.centerMarker {
  position: absolute;
  background: url(../../../img/icons/common/map-marker.png) no-repeat;
  background-size: contain;
  /*center the marker*/
  top: 50%;
  left: 50%;
  z-index: 1;
  /*fix offset when needed*/
  margin-left: -20px;
  margin-top: -40px;
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.camera-btn{
  bottom: 0;
  left: 72%;
}

// .search-restaurant{
//   filter: invert(100%) sepia(20%) saturate(1158%) hue-rotate(178deg) brightness(101%) contrast(90%);
// }

.dull-filter{
  filter: invert(93%) sepia(16%) saturate(1137%) hue-rotate(185deg) brightness(98%) contrast(90%);
}

.hint{
  top: 2px;
  right: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #ff2c55;
}