body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000;
}
ion-tab-bar {
  --background: #ffffff;
  border-top: 0.0625rem solid #e2e2e2 !important;
}
ion-tab-button {
  --color: #222222;
  --color-selected: #0058df;
  --ripple-color: #0058df;
  &.tab-selected {
    font-weight: bold;
  }
}

ion-content {
  // --background: #f7f8fa;
  //--background: #f5f5f5;
  // --offset-bottom: auto !important;
  // --overflow: hidden;
  // overflow: auto;
  // &::-webkit-scrollbar {
  //   display: none;
  //   width: 5px;
  // }
  scrollbar-width: none;
}

.featured-badge {
  position: absolute;
  top: 0;
  left: 0em;
  &:before {
    content: "Featured";
    position: absolute;
    top: 0;
    left: 0;
    padding-right: 10px;
    height: 0px;
    border: 12px solid #ff2c55;
    border-right-color: transparent;
    line-height: 0px;
    box-shadow: -0px 5px 5px -5px #000;
    color: #fff;
    font-size: 12px;
    z-index: 1;
  }
  &:after {
    content: "";
    top: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    width: 100%;
    height: 22px;
    position: absolute;
    z-index: 1;
    -webkit-animation: slide 2.2s infinite;
    animation: slide 2.2s infinite;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff',endColorstr='#007db9e8',GradientType=1);
  }
}
.ion-page {
  bottom: -1px;
}
ion-content {
  --background: url(../img/intro/background.png);
  --padding-bottom: 20px;
}

ion-select.menu {
  color: #b61120 !important;
  padding-left: 0;
  font-weight: normal;
  padding-bottom: 0;
}
ion-radio,
ion-checkbox {
  margin: 15px 0px;
}
ion-select.menu::part(icon) {
  opacity: 0;
}
.order-type {
  position: relative;
  color: #b61120 !important;
  margin-top: 10px;
  display: inline-block;
  font-weight: bold;
  &:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-left: 2px solid #b61120;
    border-top: 2px solid #b61120;
    transform: rotate(-135deg);
    right: -17px;
    top: 6px;
  }
}

ion-badge {
  position: absolute;
  right: -6px;
  top: -7px;
  border-radius: 10px;
  font-size: 9px;
  min-width: 18px;
  min-height: 18px;
  padding-top: 4px;
  padding-left: 0px;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
ion-item {
  width: 100%;
  --inner-border-width: 0 0 0 0;
  --min-height: 24px;
}
.ht-tab ion-label {
  font-size: 12px;
}
ion-label {
  margin: 0 0px 0 0;
  
}

ion-radio::part(container) {
  --border-width: 1px;
}

ion-checkbox::part(container) {
  --border-width: 1px;
}
ion-checkbox.checkbox-checked::after {
  content: "";
  height: 18px;
  width: 18px;
  position: absolute;
  top: 0;
}

ion-icon.tab-menu {
  height: 15px;
  width: 15px;
  margin-bottom: 5px;
}
.padding-status-bar.reward {
  padding-top: calc(21px + var(--ion-safe-area-top, 0));
}
.ht-ion-fab {
  bottom: 0;
  right: 0;
  width: 100%;
}
.status-bar-gradient.not-ios{
  border-bottom: 1px solid #E2E2E2;
  // --background: linear-gradient(
  //   to bottom,
    
  //   rgba(51, 51, 51, 0.1) 0px,
  //   rgba(51, 51, 51, 0.1) var(--ion-safe-area-top, 0),
  //   rgba(255, 255, 255, 0) var(--ion-safe-area-top, 0)
  // );
  // background: linear-gradient( 
  //   to bottom,
  //   rgba(51, 51, 51, 0.1) 0px,
  //   rgba(51, 51, 51, 0.1) var(--ion-safe-area-top, 0),
  //   rgba(255, 255, 255, 0) var(--ion-safe-area-top, 0)
  // );
}
.ht-alert.ios {
  .alert-input-group {
    margin-bottom: 20px;
    input {
      margin-top: -2px;
    }
    
  }
  .alert-message{
    margin-bottom: 10px;
  }
}
.input-status-bar{
    top:  15px;
}
.padding-status-bar{
  padding-top: calc( var(--ion-safe-area-top, 0));
}
.ht-ios{
  height: calc(58px + var(--ion-safe-area-top, 0));
  padding-top: var(--ion-safe-area-top, 0);
}
.blue-back-safe-are {
  position: relative;
  top: var(--ion-safe-area-top, 0);
}
ion-toolbar {
  display: flex;
  align-items: center;
  height: calc(49px + var(--ion-safe-area-top, 0));
  //  border-bottom: 1px solid #E2E2E2;
}
ion-modal.ht-custom-class {
  ion-backdrop {
    background-color: transparent;
  }
  .action-sheet-container {
    padding: 0;
  }
}
.header-md::after {
  background-image: none;
}
.product-detail {
  ion-checkbox,
  ion-radio {
    margin: 12px 0px 12px 15px;
  }
}
.inner-scroll {
  padding-bottom: 0 !important;
}

ion-input.add-address {
  --padding-top: 5px;
  --padding-start: 0px;
  --padding-bottom: 0px;
}
.ht-toast {
   text-align: center;
   --max-width: 100px;
  .toast-message {
    text-align: center;
  }
  
}

.oder-time ion-item.item-radio-checked ion-label {
  --color: #0042A8 !important;
  font-weight: bold;
}
.h-find_icon2 {
  position: absolute;
  top: 13px;
  left: 15px;
}

.banner-blur {
  display: none;
}

.my-ionic-web {
  .sc-ion-modal-md-h {
    height: 100% !important;
    width: 100% !important;
    .modal-wrapper {
      height: 100% !important;
      width: 100% !important;
    }
  }
  .info {
    width: 370px !important;
    left: -187px !important;
  }
  ion-content {
    .login-web {
      margin-top: 10% !important;
      img {
        height: 200px;
      }
      
    }
    .form-login-web {
      width: 322px;
      margin: auto;
    }
    .my-container {
      margin: auto;
      
      .border {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      .no-repeat {
        background-repeat: no-repeat;
      }
      .banner {
        max-height: calc(100vh* 0.4);
        background-size: contain;
        background-position-x: center;
        
        border: none !important;
      }
      .banner-blur {
        position: absolute;
        width: 100%;
        top: 10px;
        background-repeat: repeat;
        filter: blur(1px);
        z-index: -1;
        display: block;
      }
    }
  }
}