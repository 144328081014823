.h-center-text-bold {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
}

.h-ion-header {
    z-index: 1;
    border-bottom: 1px solid #E2E2E2;
    background-color: #fff !important;
}

ion-header ion-toolbar img {
    width: 38px;
    padding: 0 20px 0 0;
    position: absolute;
    top: 0px;
    height: 100%;
    left: 20px;
}

.toast-message {
    text-align: center;
}

.h-col-2 {
    max-width: 16.66667%;
}

.h-col-3 {
    max-width: 25%;
}

.h-col-4 {
    max-width: 33.33333%;
}

.h-col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.h-col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.h-col-8 {
    max-width: 66.66667%;
}

h.col-9 {
    max-width: 75%;
}

.h-col-10 {
    max-width: 83.33333%;
}

.h-color-black {
    color: black;
}

.h-bold {
    font-weight: bold;
}

.pl-20 {
    padding-left: 20px;
}

.h-btn-alws-bottom {
    padding: 13px;
    background-color: #fff;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
}

.h-footer-alws-bottom {
    padding: 13px;
    height: 50px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #D8D8D8;
}

.btn-border-rdu-30 {
    width: 100%;
    border-radius: 30px;
    height: 44px;
    background-color: #045ADE;
    color: #fff;
    font-size: 18px;
}

.h-input-form2 {
    height: 53px;
    --background: #fff;
    --color: var(--ion-color-step-550, #737373);
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000017;
    width: 100%;
    background-color: #fff;
    border: none;
    padding: 0 20px;
}

.h-input-form {
    height: 53px;
    --background: #fff;
    --color: var(--ion-color-step-550, #737373);
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000017;
    width: 100%;
}

.h-input-form.postal-code {
    padding: 0 12px !important;
}

.h-input-form .item-native {
    height: 100%;
}

.h-datetime::part(placeholder), .h-datetime::part(text) {
    height: 100%;
    display: flex;
    align-items: center;
}

.h-form-ordertime {
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
    --color: var(--ion-color-step-550, #737373);
    border-radius: 20px;
    font-size: 15px;
    box-shadow: 0px 2px 3px #0000001A;
    width: 100%;
}

.h-form-restaurant {
    --background: #000;
    --color: var(--ion-color-step-550, #737373);
    background-color: #000;
    border-radius: 20px;
    font-size: 14px;
    box-shadow: 0px 2px 3px #0000001A;
    width: 100%;
}

.h-line-e3e3e3 {
    border-bottom: 1px solid #e3e3e3;
}

.h-width-150px {
    width: 150px;
}

.mlr-20 {
    margin-left: 20px;
    margin-right: 20px;
}

.h-flex {
    display: flex;
}

.h-flex .col-6 {
    width: 50%;
}

.h-flex .col-12 {
    width: 100%;
}

.security {
    -webkit-text-security: disc;
}

.h20px {
    box-sizing: border-box;
    display: flex;
}

.h-bgr-fff {
    background-color: #fff;
}

.h-between {
    justify-content: space-between;
}

.j-start {
    justify-content: flex-start;
}

.j-center {
    justify-content: center;
}

.j-end {
    justify-content: flex-end;
}

.a-center {
    align-items: center;
}

.h-pt-2 {
    padding-top: 2px;
}

.h-pt-5 {
    padding-top: 5px;
}

.pt-10 {
    padding-top: 10px;
}

.h-pl-5 {
    padding-left: 5px;
}

.h-pl-10 {
    padding-left: 10px;
}

.h-pl-13 {
    padding-left: 13px;
}

.h-plr-0 {
    padding-left: 0px;
    padding-right: 0px;
}

.h-plr-15 {
    padding-left: 15px;
    padding-right: 15px;
}

.h-plr-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.h-pl-20 {
    padding-left: 20px;
}

.h-pl-30 {
    padding-left: 30px;
}

.h-pr-5 {
    padding-right: 5px;
}

.h-pr-10 {
    padding-right: 10px;
}

.h-pr-20 {
    padding-right: 20px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pt-55 {
    padding-top: 55px;
}

.h-pb-5 {
    padding-bottom: 5px;
}

.h-pb-15 {
    padding-bottom: 15px;
}

.h-pb-20 {
    padding-bottom: 20px;
}

.h-pb-25 {
    padding-bottom: 25px;
}

.h-ml-3 {
    margin-left: 3px;
}

.h-ml-5 {
    margin-left: 5px;
}

.h-mr-5 {
    margin-right: 5px;
}

.h-mlr-5 {
    margin-left: 5px;
    margin-right: 5px;
}

.h-mlr-15 {
    margin-left: 15px;
    margin-right: 15px;
}

.h-mt-10 {
    margin-top: 10px;
}

.h-mr-3 {
    margin-right: 3px;
}

.h-mt-3 {
    margin-top: 3px;
}

.h-mr-5 {
    margin-right: 5px;
}

.h-pb-10 {
    padding-bottom: 10px;
}

.h-mb-10 {
    margin-bottom: 10px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.h-pb-30 {
    padding-bottom: 30px;
}

.h-pb-40 {
    padding-bottom: 40px;
}

.pb-100 {
    padding-bottom: 100px;
}

.height-60 {
    height: 60px;
}

.h-max {
    width: 100%;
}

.h-radio-custom {
    width: 20px;
    height: 30px;
}

.color-045ADE {
    color: #045ADE;
}

.h-font-15 {
    font-size: 15px;
}

.h-font-16 {
    font-size: 16px;
}

.h-font-17 {
    font-size: 17px;
}

.h-font-18 {
    font-size: 18px;
}

.h-font-19 {
    font-size: 19px;
}

.h-font-22 {
    font-size: 22px;
}

.h-font-24 {
    font-size: 24px;
}

.h-font-11 {
    font-size: 11px;
}

.h-font-12 {
    font-size: 12px;
}

.h-font-13 {
    font-size: 13px;
}

.h-font-14 {
    font-size: 14px;
}

.h-color-C81021 {
    color: #C81021;
}

.h-color-707070 {
    color: #707070;
}

.h-form-de-in {
    height: 50px;
    border: 2px solid #c7c7c7;
    border-radius: 30px;
    background-color: #fff;
}

.h-height-100 {
    height: 100%;
}

.h-form-de-in .mot {
    text-align: center;
    font-size: 15px;
    color: #045ADE;
    cursor: pointer;
}

.h-form-de-in .cong {
    font-size: 25px;
    cursor: pointer;
}

.h-border-0pc {
    border-radius: 0px !important;
}

.h-form-de-in .tru {
    font-size: 25px;
    cursor: pointer;
}

.h-payment__header {
    height: 50px;
    border-bottom: 1px solid #E7E7E7;
    position: relative;
}

.h-payment__header .h-000E90 {
    /* color: #000E90; */
    font-size: 18px;
    color: #222222;
}

.h-payment__header .h-okelelele::after {
    content: "";
    position: absolute;
    height: 10px;
    margin: auto;
    top: 0;
    bottom: 0;
    border-left: 1px solid #babfc5;
    margin-left: 10px;
}

.h-payment__header .h-date {
    color: #AAAAAA;
    font-size: 14px;
    margin-left: 10px;
}

.h-button-red {
    background-color: #C81021;
}

.btn-order {
    background-color: #F39A37 !important;
}

.btn-cancel {
    background-color: #EB4C4C !important;
}

.btn-complete {
    background-color: #2CBEA5 !important;
}

.btn-confirm {
    background-color: #0850f3 !important;
}

.h-button_payment_header {
    width: 90px;
    height: 26px;
    color: #fff !important;
    font-size: 11px !important;
    font-weight: bold;
    border-radius: 5px;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.h-body-img-product-payment1 {
    background-image: url('../../assets/img/product/food1.jpg');
    width: 70px;
    height: 70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 18px;
}

.h-body-img-product-payment2 {
    background-image: url('../../assets/img/product/food2.jpg');
    width: 70px;
    height: 70px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #e9edf0;
    border-radius: 18px;
}

.h-flex__nowrap {
    flex-wrap: nowrap;
}

.h-flex__wrap {
    flex-wrap: wrap;
}

.h-flex-column {
    flex-direction: column;
}

.h-width-50pc {
    width: 50%;
}

.h-width-48pc {
    width: 48%;
}

label {
    margin-bottom: 0 !important;
}

.h-text-small {
    font-size: 13px;
    color: #737373;
}

.h-line__top {
    border-top: 1px solid #F0F0F0;
}

.h-line__bottom {
    border-bottom: 1px solid #F0F0F0;
}

.h-footer-payment__icon img {
    width: 17px;
    height: 17px;
}

.h-space-around {
    justify-content: space-around;
}

.h-color-blue {
    color: #045ADE;
}

.h-select__custom::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    height: 8px;
    width: 8px;
    border-left: 1px solid #616161;
    border-bottom: 1px solid #616161;
    transform: rotate( 225deg) translateY(-50%);
}

.h-img-rewards {
    width: 120px;
    height: 120px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
}

.h-img-rewards1 {
    background-image: url('../../assets/img/product/reward1.jpg');
}

.h-img-rewards2 {
    background-image: url('../../assets/img/product/reward2.jpg');
}

.h-img-rewards3 {
    background-image: url('../../assets/img/product/reward3.jpg');
}

.h-img-rewards4 {
    background-image: url('../../assets/img/product/reward4.jpg');
}

.h-color-045ADE {
    color: #045ade;
}

.h-restaurant-address {
    display: flex;
    flex-direction: column;
    max-width: calc(100% - 15px);
}

.h-restaurant-address label {
    line-height: 20px;
}

.h-logo-chicko-chicken-center {
    background-image: url('../../assets/img/icons/common/logoImage_profile.svg');
    width: 110px;
    height: 110px;
    border-radius: 100%;
    background-position: center;
    position: relative;
    top: -3rem;
    background-size: 80%;
    background-repeat: no-repeat;
    background-color: #fff;
    box-shadow: 0px 3px 20px #00000029;
}

.h-position__relative {
    position: relative;
}

.h-height-70px {
    height: 70px;
}

.h-height-100px {
    height: 100px;
}

.h-position__absolute {
    position: absolute;
}

.h-input__profile {
    border: none;
    color: #707070;
    background-color: #fff;
}

.h-form__profile {
    margin: 10px 0px 10px 15px;
    width: 100%;
}

.h-form__profile input {
    width: 100%;
}

.h-form-search-address {
    position: relative;
}

.h-find_icon {
    position: absolute;
    top: 4px;
    left: 15px;
}

.h-remove_icon {
    position: absolute;
    top: 4px;
    right: 11px;
}

.h-input-form__search__address {
    height: 35px;
    border-radius: 30px;
    border: none;
    --background: var(--ion-item-background, var(--ion-background-color, #fff));
    --color: var(--ion-color-step-550, black);
    font-size: 14px;
    box-shadow: 0px 2px 3px #0000001A;
    padding: 0px 40px 0px 40px;
}

.h-checkout__header {
    border-bottom: 2px solid #E0E0E0;
}

.check-checkout__header--price {
    font-size: 33px;
    font-weight: bold;
    color: #045ADE;
}

.h-lineeee::after {
    content: "";
    position: absolute;
    left: calc(50% - 15px);
    top: 40px;
    margin: auto;
    width: 30px;
    border-bottom: 3px solid #616161;
    transform: rotate( 180deg) translateX(0%);
}

.h-select__payment-method {
    border-radius: 14px;
    background-color: #fff;
    box-shadow: 0px 2px 3px #0000001A;
}

.h-pd-30 {
    padding: 30px;
}

.h-form-de-in__payment label.h-font-22 {
    font-family: revert;
}

.h-form-de-in__payment {
    height: 35px;
    border: 1px solid #c1c1c1;
    border-radius: 30px;
    background-color: #fff;
    width: 95px;
}

.h-select-tip {
    height: 37px;
    width: 23%;
    border: none;
    border-radius: 30px;
    background-color: #F5F5F5;
}

.h-select-tip.active {
    border: 2px solid #C81021;
    color: #C81021;
    background-color: #fff;
}

.h-form-de-in__payment .number {
    text-align: center;
    color: #045ADE;
    font-size: 19px;
}

.h-lh-18 {
    line-height: 18px;
}

ul.country-list {
    width: calc(100vw - 55px) !important;
}

.alert {
    border-radius: 25px;
}

.alert-warning {
    background: red;
}

.alert-dismissible .close {
    top: 50%;
}

.sub-info {
    font-size: 15px !important;
    color: #5D5D5D;
    line-height: 20px !important;
}

.mg-b5 {
    margin-bottom: 5px;
}

.mg-b10 {
    margin-bottom: 10px;
}

input::placeholder, textarea::placeholder {
    color: #A6A6A6;
    opacity: 0.55;
}

.payment label {
    font-size: 16px;
    color: #444444
}

.edit-profile ion-label {
    display: flex;
}

.infinite-loading {
    display: block !important;
}

.result-message {
    line-height: 22px;
    font-size: 16px;
}

.result-message a {
    color: rgb(89, 111, 214);
    font-weight: 600;
    text-decoration: none;
}

.hidden {
    display: none;
}

.card-error {
    color: red;
    text-align: center;
    font-size: 15px;
    line-height: 17px;
    margin-top: 12px;
}

#card-element {
    border-radius: 4px 4px 0 0;
    padding: 12px;
    border: 1px solid rgba(50, 50, 93, 0.1);
    height: 44px;
    width: 100%;
    background: white;
}

button.ht-button {
    transition: all 0.2s ease;
}

button.ht-button:hover {
    filter: contrast(115%);
}

button.ht-button:disabled {
    cursor: default;
}

/* spinner/processing state, errors */

.spinner, .spinner:before, .spinner:after {
    border-radius: 50%;
}

.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.spinner:before, .spinner:after {
    position: absolute;
    content: "";
}

.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #045ADE;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #045ADE;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.StripeElement {
    height: 54px;
    padding: 18px 20px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 20px;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    margin-bottom: 15px;
    box-shadow: 0px 3px 6px #00000017;
}

.card-form {
    display: flex;
    flex-direction: column;
}

.card-form label {
    color: #333333;
    font-size: 18px;
    display: flex;
    margin-bottom: 3px !important;
    font-weight: bold;
}

.card-form.card-exp {
    margin-right: 30px;
}

.card-form.card-cvc, .card-form.card-exp {
    width: calc(50% - 15px);
    display: inline-flex;
}

.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.ht-alert .alert-wrapper {
    border-radius: 15px;
}

.ht-alert .alert-head {
    padding-bottom: 10px;
}

.ht-alert .alert-message {
    padding: 0 20px 5px;
    text-align: center;
    color: #333333;
}

.h-font-20 {
    font-size: 20px;
}

.ht-alert .alert-head h2 {
    text-align: center;
    font-weight: bold;
}

.ht-alert .alert-input-group {
    padding: 0 20px;
}

/* .picker-above-highlight.sc-ion-picker-md, .picker-below-highlight.sc-ion-picker-md {
    display: block !important;
} */

.picker-wrapper {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    padding: 0 30px !important;
}

.picker-wrapper .picker-columns {
    margin-top: 30px;
}

.picker-columns .sc-ion-picker-md:nth-child(2)::after {
    position: absolute;
    content: 'Hour';
    top: 0;
    z-index: 1;
    background-color: white;
}

.picker-below-highlight.sc-ion-picker-md {
    display: block !important;
}

.picker-above-highlight.sc-ion-picker-md {
    top: 25px !important;
    height: 55px !important;
    display: block !important;
}

.picker-toolbar.sc-ion-picker-md {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 5px;
    width: calc(100% - 65px);
}

.picker-toolbar.sc-ion-picker-md button {
    font-size: 18px;
}

.picker-columns .sc-ion-picker-md:nth-child(3)::after {
    position: absolute;
    content: 'Minute';
    top: 0;
    z-index: 1;
    background-color: white;
}

.picker-columns .sc-ion-picker-md:nth-child(5)::after {
    position: absolute;
    content: 'Month';
    top: 0;
    z-index: 1;
    background-color: white;
}

.picker-columns .sc-ion-picker-md:nth-child(6)::after {
    position: absolute;
    content: 'Day';
    top: 0;
    z-index: 1;
    background-color: white;
}

.ht-ion-item {
    --border-color: transparent;
    right: -17px;
    position: relative;
}

.ht-ion-item ion-datetime {
    position: absolute;
    opacity: 0;
}

.ht-datetime {
    height: 150px;
    width: 100%;
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 30px;
}

.ht-datetime::after {
    position: absolute;
    content: '';
    height: 40px;
    width: 100%;
    top: 40px;
    background-color: rgb(89, 111, 214, 0.2);
}

.ht-datetime .swiper-slide.swiper-slide-next, .ht-datetime .swiper-slide.swiper-slide-prev {
    font-size: 16px !important;
    color: #999;
    opacity: 0.75;
    transform: scaleY(0.85);
}

.ht-datetime .swiper-slide.swiper-slide-active {
    font-size: 20px;
    opacity: 1;
    color: #0850f3;
    transform: scaleY(1);
}

.ht-datetime .swiper-slide {
    font-size: 14px;
    color: #999;
    opacity: 0.5;
    transform: scaleY(0.65);
}

.ht-slide {
    height: 35px;
    width: 40px;
    overflow: visible;
}

.ht-date {
    display: flex;
    margin-right: 30px;
}

.ht-time {
    display: flex;
}

ion-backdrop {
    opacity: 0.3;
}

ion-backdrop.backdrop-hide {
    z-index: -1;
}

.ht-choose {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 25px;
}

.ht-choose button {
    background: none;
    font-size: 14px;
    width: 100px;
    background-color: #045ADE;
    color: #fff;
    border-radius: 5px;
    margin-right: 15px;
    height: 27px;
}

.ht-header-datetime {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 12px 20px 8px;
    border-bottom: 1px solid #eeeeee;
}

.ht-header-datetime .ht-title {
    font-weight: bold;
    font-size: 17px;
}

ion-tab-button ion-label {
    color: #222222 !important;
}

ion-tab-button.tab-selected ion-label {
    color: #0058df !important;
}

ion-item.ht-ion-item {
    --background: transparent;
    --padding-start: 0;
    left: 0;
    --inner-padding-end: 0;
    border-radius: 20px;
    margin-bottom: 15px;
    box-shadow: rgb(0 0 0 / 9%) 0px 2px 3px;
}