button:focus {
  outline: none;
}

.btn-app-download {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.foodlicious-navbar .navbar-nav .nav-link {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  color: #000000;
}
.foodlicious-navbar .navbar-nav .nav-link:hover,
.foodlicious-navbar .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.8);
}

.navbar-toggler {
  border: 0;
}
.navbar-brand img{
  height: 60px;
}

.foodlicious-header {
  padding-top: 24px;
}
.foodlicious-header .header-content {
  padding-bottom: 40px;
}
.foodlicious-header .header-content h1 {
  font-size: 45px;
  line-height: 1.22;
}
.foodlicious-header.landing-header {
  background-image: url("../img/intro/bg_1@2x.png"),
    url("../img/intro/Bg_2@2x.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 167px, 65%;
  background-position: left bottom, right top;
}

.landing-feature-icon {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #6206a8;
  margin-right: 1rem;
}
.landing-feature-icon::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 7px;
  border: 2px solid #ffffff;
  border-color: transparent transparent #f0f0f0 #f0f0f0;
  transform: rotate(-45deg);
  position: relative;
  top: -6px;
  left: 4px;
}

.landing-feature p {
  font-size: 14px;
  color: #77838f;
  line-height: 1.79;
}


.page-header {
  padding-top: 80px;
  padding-bottom: 75px;
}
@media (max-width: 767px) {
  .page-header {
    padding-top: 30px;
    padding-bottom: 35px;
  }
}
.page-header h2 {
  font-size: 30px;
  line-height: 1.33;
}

.foodlicious-page-section {
  padding-top: 75px;
  padding-bottom: 75px;
}

.foodlicious-feature {
  margin-bottom: 46px;
}
.foodlicious-feature .feature-icon {
  width: 30px;
  margin-bottom: 17px;
}
.foodlicious-feature .feature-title {
  line-height: 1.75;
  font-size: 20px;
}
.foodlicious-feature .feature-content {
  font-size: 18px;
  color: #3a3a3a;
  margin-bottom: 12px;
  line-height: 1.56;
}
.foodlicious-feature .feature-link {
  font-size: 14px;
  font-weight: 700;
  color: #6206a8;
}
.foodlicious-feature .feature-link:hover,
.foodlicious-feature .feature-link:focus {
  color: #54058f;
}
/*# sourceMappingURL=style.css.map */

.phone {
  width: fit-content;
  border: 40px solid #ddd;
  border-width: 45px 10px;
  border-radius: 40px;
  // margin: 50px auto;
  overflow: hidden;
  transition: all 0.5s ease;
}

.phone iframe {
  border: 0;
  width: 360px;
  height: 580px;
}
/*Different Perspectives*/

.phone.laid_down {
  transform: rotateX(50deg) rotateY(0deg) rotateZ(-50deg);
  box-shadow: -3px 3px 0 #bbb, -6px 6px 0 #bbb, -9px 9px 0 #bbb,
    -12px 12px 0 #bbb, -14px 10px 20px #666;
}

.phone.awake {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  box-shadow: 0px 3px 0 #bbb, 0px 4px 0 #bbb, 0px 5px 0 #bbb, 0px 7px 0 #bbb,
    0px 10px 20px #666;
}

@media (max-width: 900px) {
  #wrapper {
    transform: scale(0.8, 0.8);
  }
}

@media (max-width: 700px) {
  #wrapper {
    transform: scale(0.6, 0.6);
  }
}

@media (max-width: 500px) {
  #wrapper {
    transform: scale(0.4, 0.4);
  }
}